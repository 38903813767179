import { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../context/authContext";
import { UserLayout } from "../layout/User";
import { SectionHead } from "../library/section/Head";
import { USERS_LIST } from "../utils/queries";
import { useQuery, useLazyQuery } from "@apollo/client";
import toast from "react-hot-toast";
import { LoadingLayout } from "../layout/Loading";
import { CreateStudentModal } from "../library/modal/CreateStudent";
import { CreateStudentForm } from "../library/form/CreateStudent";
import clsx from "clsx";
import { formatMobile, convertUnixTimestamp } from "../utils/helpers";
import { CSVLink } from "react-csv";
import { useDebounce } from "@uidotdev/usehooks";

export default function Users() {
  const { isAdmin, isManager } = useContext(AuthContext);

  return (
    <UserLayout title={`Users`}>
      {/* head section */}
      <SectionHead heading="Users List">
        <Link to={`/create/customer`} className="btn btn-outline">
          Create Customer
        </Link>
      </SectionHead>
      {/* content section */}
      <section>
        {/* table */}
        <Table isAdmin={isAdmin} isManager={isManager} />
      </section>
    </UserLayout>
  );
}

function Table(props) {
  const { isAdmin, isManager } = props;

  const navigate = useNavigate();

  const [search, setSearch] = useState("");
  const [users, setUsers] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [limit /*, setLimit*/] = useState(10);
  const [maxPage, setMaxPage] = useState(0);
  const [data, setData] = useState([]);
  const [customer, setCustomer] = useState(null);

  const debouncedSearch = useDebounce(search, 1000);

  const { loading, refetch } = useQuery(USERS_LIST, {
    onCompleted: (data) => {
      setUsers(data.list?.users);
      setTotal(data.list?.total || 0);
      setMaxPage(Math.ceil(data.list.total / limit));
    },
    onError: (error) => {
      console.error(error);
      toast.error("Failed to load users");
    },
    variables: {
      search: !!debouncedSearch ? debouncedSearch : null,
      page,
      limit,
    },
    fetchPolicy: "network-only",
    pollInterval: 5000,
  });

  const [getUsersToDownload, { data: download, loading: downloading }] =
    useLazyQuery(USERS_LIST, {
      onCompleted: (res) => {
        if (res.list?.users?.length > 0) {
          const formatted = res.list.users.map((user, i) => ({
            "No.": i + 1,
            Email: user.email,
            Phone: user.phone ? formatMobile(user.phone) : "-",
            Street: user.street ?? "-",
            Suburb: user.suburb ?? "-",
            Postcode: user.postcode ?? "-",
            Students: user.studentsCount ?? 0,
            Registered: convertUnixTimestamp(user.created),
          }));

          setData(formatted);
        }
      },
      onError: (error) => {
        console.error(error);
        toast.error("Unable to get students list for download!");
      },
      fetchPolicy: "network-only",
    });

  const getDownloadData = () => {
    getUsersToDownload({
      variables: {
        search: !!debouncedSearch ? debouncedSearch : null,
        page: 0,
        limit: total,
      },
    });
  };

  const handleUpdate = (id) => {
    navigate(`/edit/customer/${id}`);
  };

  const handleAddStudent = (id, email) => {
    setCustomer({ id, email });
  };

  const nextPage = () => {
    // if page is max page, then do nothing
    if (page === maxPage - 1) return;
    setPage(page + 1);
    refetch();
  };

  const prevPage = () => {
    // if page is 0, then do nothing
    if (page === 0) return;
    setPage(page - 1);
    refetch();
  };

  return (
    <>
      <div className="overflow-x-auto rounded-xl shadow-md my-4 bg-white">
        {/* table header */}
        <div className="flex w-full px-4 py-4 border-b-2">
          <div className="flex flex-row justify-between items-center w-full gap-4">
            {/* filters */}
            <div className="basis-1/2 flex flex-row gap-4">
              {/* name search */}
              <div className="w-full">
                <label className="label sr-only">
                  <span className="label-text font-semibold">Name</span>
                </label>
                <input
                  type="search"
                  placeholder="Search by email or phone"
                  className="input input-bordered w-full bg-white"
                  onChange={(e) => setSearch(e.target.value)}
                  value={search}
                />
              </div>
            </div>
            {/* buttons */}
            <div className="flex flex-row gap-2">
              {(isAdmin || isManager) && (
                <>
                  {/* download csv */}
                  {download && download?.list?.users.length === data.length ? (
                    <CSVLink
                      className="btn btn-ghost"
                      data={data}
                      filename={`users.csv`}
                      onClick={(e) => {
                        setData([]);
                      }}
                    >
                      Download CSV
                    </CSVLink>
                  ) : (
                    <button
                      className={clsx(
                        "btn btn-ghost",
                        downloading && "loading"
                      )}
                      onClick={getDownloadData}
                    >
                      Generate CSV
                    </button>
                  )}
                </>
              )}
              {/* reset button */}
              <button
                className="btn btn-ghost"
                onClick={() => {
                  setSearch("");
                  setData([]);
                  refetch();
                }}
              >
                Reset
              </button>
            </div>
          </div>
        </div>
        {/* table content */}
        {!loading ? (
          <table className="table-normal divide-y-2 bg-white rounded-xl w-full">
            {/* head */}
            <thead className="text-left">
              <tr>
                <th></th>
                <th>Name</th>
                <th>Students</th>
                <th>Suburb</th>
                <th>Registered</th>
                <th></th>
              </tr>
            </thead>
            {/* body */}
            {users?.length > 0 ? (
              <tbody className="divide-y-2">
                {users?.map((value, index) => (
                  <tr key={value.id}>
                    <th>{page * limit + index + 1}</th>
                    <td className="flex flex-row gap-4 items-center">
                      <div>
                        <p className="font-bold">{value.email}</p>
                        <p>
                          <span className="capitalize tracking-wider">
                            {formatMobile(value.phone)}
                          </span>
                        </p>
                      </div>
                    </td>
                    <td className="tracking-wider font-mono">
                      <span>{value.studentsCount}</span>
                    </td>
                    <td>
                      <span className="font-medium capitalize tracking-wider">
                        {value?.suburb ?? ""} {value?.postcode ?? ""}
                      </span>
                    </td>
                    <td>
                      <span className="tracking-wide">
                        {convertUnixTimestamp(value.created)}
                      </span>
                    </td>
                    <td>
                      <button
                        className={clsx(
                          "btn btn-xs btn-ghost",
                          !(isAdmin || isManager) && "hidden"
                        )}
                        onClick={() => handleUpdate(value.id)}
                      >
                        Update
                      </button>
                      <label
                        htmlFor="student-modal-create"
                        className={clsx(
                          "btn btn-xs btn-ghost",
                          !(isAdmin || isManager) && "hidden"
                        )}
                        onClick={() => handleAddStudent(value.id, value.email)}
                      >
                        Add Student
                      </label>
                    </td>
                  </tr>
                ))}
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <td colSpan="7">
                    <div className="flex flex-col items-center justify-center py-8">
                      <p className="text-gray-400 text-lg">No users found</p>
                    </div>
                  </td>
                </tr>
              </tbody>
            )}
          </table>
        ) : (
          <>
            {/* loading skeleton */}
            <LoadingLayout type="list" />
          </>
        )}
        {/* table footer */}
        <div className="flex w-full px-4 py-4 border-t-2">
          <div className="flex flex-row justify-between items-center w-full">
            <div>
              <p>
                Showing <span className="font-bold">{page * limit + 1}</span> -{" "}
                <span className="font-bold">
                  {page * limit + users?.length}
                </span>{" "}
                of <span className="font-bold">{total}</span> results
              </p>
            </div>
            <div className="btn-group">
              <button
                className="btn btn-outline btn-sm"
                disabled={page === 0 || maxPage <= 1}
                onClick={() => prevPage()}
              >
                Prev
              </button>
              <button
                className="btn btn-outline btn-sm"
                disabled={page === maxPage - 1 || maxPage <= 1}
                onClick={() => nextPage()}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
      <CreateStudentModal
        title="Add a new student"
        content={`Add a new student for: ${customer?.email}`}
        label="Add Student"
        btn="hidden"
      >
        <CreateStudentForm user={customer} />
      </CreateStudentModal>
    </>
  );
}
