import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useLazyQuery } from "@apollo/client";
import { ADD_SESSION, UPDATE_SESSION } from "../../utils/mutations";
import { SESSION_DETAILS } from "../../utils/queries";
import toast from "react-hot-toast";
import clsx from "clsx";

export function SessionForm(props) {
  const { mode = "add", id, payload, refetch } = props; // enitity is the parent object

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors, isValid },
    setValue,
  } = useForm({
    mode: "onTouched",
  });

  const watchStart = watch("session.start");

  const [addSession, { loading: adding }] = useMutation(ADD_SESSION, {
    onCompleted: (data) => {
      if (data.created) {
        refetch();
        reset();
        toast.success("Session added successfully");
      }
    },
    onError: (error) => {
      console.error(error);
      toast.error("Failed to add session");
    },
  });

  const [updateSession, { loading: updating }] = useMutation(UPDATE_SESSION, {
    onCompleted: (data) => {
      if (data.updated) {
        refetch();
        reset();
        toast.success("Session updated successfully");
      }
    },
    onError: (error) => {
      console.error(error);
      toast.error("Failed to update session");
    },
  });

  const [prefill] = useLazyQuery(SESSION_DETAILS, {
    onCompleted: (data) => {
      if (data.details) {
        const { start, end } = data.details;
        setValue("session.start", start);
        setValue("session.end", end);
      }
    },
    onError: (error) => {
      console.error(error);
      toast.error("Failed to get session details");
    },
  });

  const onSubmit = (data) => {
    const { start, end } = data.session;

    const input = {
      id,
      start,
      end,
      type: payload.type.toUpperCase(),
      ...(payload.type === "class" && { classId: payload.id }),
      ...(payload.type === "training" && { trainingId: payload.id }),
      ...(payload.type === "hire" && { hireId: payload.id }),
      ...(payload.type === "workshop" && { workshopId: payload.id }),
    };

    if (mode === "add") {
      addSession({ variables: { input } });
    } else if (mode === "edit") {
      updateSession({ variables: { input } });
    }
  };

  useEffect(() => {
    if (mode === "edit" && id) {
      prefill({ variables: { sessionId: id } });
    }
  }, [mode, id, prefill]);

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <label className="label">
            <span className="label-text font-semibold">Start</span>
          </label>
          <input
            type="datetime-local"
            min={
              payload?.range?.from
                ? new Date(payload.range.from).toISOString().slice(0, 16)
                : null
            }
            max={
              payload?.range?.to
                ? new Date(payload.range.to).toISOString().slice(0, 16)
                : null
            }
            className={clsx(
              "input input-bordered w-full bg-white",
              errors?.session?.start && "input-error"
            )}
            {...register("session.start", { required: true })}
          />
        </div>
        <div>
          <label className="label">
            <span className="label-text font-semibold">End</span>
          </label>
          <input
            type="datetime-local"
            disabled={!watchStart}
            min={watchStart}
            max={
              payload?.range?.to
                ? new Date(payload.range.to).toISOString().slice(0, 16)
                : null
            }
            className={clsx(
              "input input-bordered w-full bg-white",
              errors?.session?.end && "input-error"
            )}
            {...register("session.end", {
              required: true,
              validate: (value) => {
                // make sure end is greater than start and the date is same as start
                const start = new Date(watchStart);
                const end = new Date(value);

                if (end <= start) {
                  return "End time must be greater than start time";
                }
                if (end.getDate() !== start.getDate()) {
                  return "End time must be on the same day as start time";
                }
                return true;
              },
            })}
          />
        </div>
        {/* submit */}
        <div className="pt-4 space-x-2">
          <button
            type="submit"
            className={clsx("btn btn-primary", adding && "loading")}
            disabled={!isValid || adding || updating}
          >
            {mode === "add" && <span>Add Session</span>}
            {mode === "edit" && <span>Update Session</span>}
          </button>
        </div>
      </form>
    </div>
  );
}
